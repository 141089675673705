// Variables
$primary-color: #009bdf;
$light-color: #efefef;
$font-color: #333;
$background-color: #fff;
$box-shadow-light: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  0 10px 10px -5px rgba(0, 0, 0, 0.04);
$box-shadow-dark: 0 20px 27px 0 rgba(0, 0, 0, 0.05);

// Breakpoints
$breakpoint-xs: 480px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1440px;

@mixin respond-to($breakpoint, $type: max) {
  @if $breakpoint == xs {
    @if $type == max {
      @media (max-width: $breakpoint-xs) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $breakpoint-xs) {
        @content;
      }
    }
  } @else if $breakpoint == sm {
    @if $type == max {
      @media (max-width: $breakpoint-sm) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $breakpoint-sm) {
        @content;
      }
    }
  } @else if $breakpoint == md {
    @if $type == max {
      @media (max-width: $breakpoint-md) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $breakpoint-md) {
        @content;
      }
    }
  } @else if $breakpoint == lg {
    @if $type == max {
      @media (max-width: $breakpoint-lg) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $breakpoint-lg) {
        @content;
      }
    }
  } @else if $breakpoint == xl {
    @if $type == max {
      @media (max-width: $breakpoint-xl) {
        @content;
      }
    } @else if $type == min {
      @media (min-width: $breakpoint-xl) {
        @content;
      }
    }
  }
}

// Base styles
html,
body {
  height: 100%;
  // font-size: 12px;
  @include respond-to(xs, max) {
    font-size: 11px;
  }

  @include respond-to(sm, min) {
    font-size: 13px;
  }

  // @include respond-to(md) {
  //   font-size: 11px;
  // }

  // @include respond-to(lg) {
  //   font-size: 10px;
  // }

  @include respond-to(xl, min) {
    font-size: 13px;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}

body,
.bg {
  background-color: #f8f9fa;
}

// Background image styles
.bg-image {
  background: linear-gradient(
    90deg,
    rgb(126, 196, 76) 0%,
    rgb(4, 151, 220) 100%
  );

  &::before {
    background-image: url("/assets/images/bg.webp");
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.1;
  }

  .bg-gradient {
    background: linear-gradient(
      90deg,
      rgb(126 196 76 / 69%) 0%,
      rgb(4 151 220 / 76%) 100%
    );
    width: 100vw !important;
    height: 100vh;
    display: flex;
  }
}

// User icon styles
.user-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  padding: 8px;
  background: linear-gradient(
    90deg,
    rgb(126, 196, 76) 0%,
    rgb(4, 151, 220) 100%
  );
  color: #fff;
  font-size: 1.5rem;
}

// Login container styles
.login-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 50vw;
  z-index: 2;
  height: 60vh;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;

  .form-control,
  input {
    border: 1px solid #daeceb;
    background-color: #fff;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 10px;

    &:focus {
      border: 1px solid rgb(4, 151, 220);
      background-color: #fff;
    }
  }

  @include respond-to(sm) {
    width: 80vw;
  }

  @include respond-to(xs) {
    width: 90vw;
    padding: 20px;
  }
}

// Sidebar styles
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 0;

  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 55px);
    overflow-y: auto;
    width: 100%;
    .nav-item {
      border-radius: 8px;
      display: block;

      &.selected .nav-link {
        // Customize selected nav-link if needed
      }
    }

    .nav-link {
      color: $font-color;
      text-transform: uppercase;
      font-size: 0.8em;
      line-height: 1em;
      font-weight: 700;
      padding: 15px 20px;
      border-radius: 8px;
      display: block;
      @include respond-to(sm, max) {
        padding: 15px;
      }

      &.active {
        color: #ffffff;
        box-shadow: 0 0 8px 5px hsl(0deg 0% 0% / 13%),
          0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
        background: #a1ce53;
        margin: 0px 10px;
        padding: 20px;
        background: linear-gradient(
          90deg,
          rgb(126 196 76) 0%,
          rgb(4 151 220) 100%
        );
        @include respond-to(sm, max) {
          padding: 20px 15px;
          margin: 0;
          box-shadow: none !important;
        }
      }

      .nav-icon {
        margin-right: 15px;
        width: 20px;
      }
    }

    .navbar-brand {
      margin: 30px;
      display: block;
      @include respond-to(sm, max) {
        margin: 10px;
        margin-bottom: 40px;
      }
    }
  }
}

// Navbar styles
.navbar {
  padding: 10px;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 1em;
  margin-top: 1.5em !important;
  background: #fff;
  margin: 15px;
  z-index: 999;

  .navbar-nav {
    h5 {
      font-weight: 600;
      margin: 5px 10px;
      text-transform: capitalize;
      font-size: 1.1em;
    }

    .breadcrumb {
      margin: 5px 0;

      .breadcrumb-item a {
        text-decoration: none;
        color: #888;
      }
    }

    .notification-icon {
      margin-top: 10px;
      display: block;
      margin-right: 10px;
      color: #199bd2;
    }
  }
}

// Table styles
table {
  background-color: $background-color;
  color: rgb(29, 38, 48);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 12px;
  width: 100%;
  overflow-x: auto;

  thead {
    background-color: rgb(248, 249, 250);
    border-top: 1px solid rgba(219, 224, 229, 0.65);
    border-bottom: 2px solid rgba(219, 224, 229, 0.65);

    tr {
      color: inherit;
      vertical-align: middle;
      outline: 0px;

      th {
        line-height: 1.5em;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgba(219, 224, 229, 0.65);
        text-align: left;
        color: #333c;
        padding: 12px;
        cursor: pointer;
        border-top-color: rgba(219, 224, 229, 0.65);
        border-right-color: rgba(219, 224, 229, 0.65);
        border-left-color: rgba(219, 224, 229, 0.65);
        font-size: 0.8em;
        font-weight: 700;
        text-transform: uppercase;
        min-width: 100px;
      }
    }
  }

  tbody {
    color: inherit;
    vertical-align: middle;
    outline: 0px;

    tr {
      td {
        line-height: 1.66;
        font-weight: 400;
        display: table-cell;
        vertical-align: inherit;
        border-bottom: 1px solid rgba(219, 224, 229, 0.65);
        text-align: left;
        color: rgb(29, 38, 48);
        font-size: 0.875em;
        padding: 12px;
        border-top-color: rgba(219, 224, 229, 0.65);
        border-right-color: rgba(219, 224, 229, 0.65);
        border-left-color: rgba(219, 224, 229, 0.65);
      }
    }
  }

  @include respond-to(md) {
    font-size: 0.8em;
  }

  @include respond-to(sm) {
    font-size: 0.7em;
  }
}

// Custom table styles
.custom-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 2px;
  text-align: left;
  color: #6c757d;

  th {
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    color: #333;
    font-size: 0.8rem;
    padding: 8px 15px;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
  }

  td {
    border: none;
    background-color: $background-color;
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
    padding: 3px 15px;
    font-size: 0.85rem;
    vertical-align: middle;
    max-width: 200px;
  }

  tbody tr {
    // margin-bottom: 10px;
  }
}

.list-headends {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    background: #eee;
    padding: 0 5px;
    margin-right: 5px;
  }
}

// Link styles
.link {
  color: $primary-color !important;
  font-weight: 600;
  cursor: pointer;
  text-decoration-color: #fff;
  border: none !important;
  background-color: transparent;
}

// Main content styles
.main-content {
  padding: 0;
  margin: 0;
}

// Custom dropdown styles
.cust-dropdown {
  .dropdown-toggle {
    min-width: 10rem;
    position: relative;
    text-align: left;
    padding-right: 25px;
    &.w-100 {
      width: 100% !important;
    }
    &::after {
      position: absolute;
      right: 10px;
      top: 45%;
    }
  }
  .dropdown-menu {
    &.show {
      max-height: 250px;
      overflow: auto;

      a.dropdown-item {
        font-size: 0.85rem;
        font-weight: 600;
        padding: 6px 12px;
      }
    }
  }
}

// Multi-select dropdown styles
.multi-select-dropdown {
  position: relative;
  display: inline-block;
}

// Dropdown menu styles
.dropdown-menu {
  border: none;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

// Dropdown styles
.dropdown {
  .form-check {
    margin-bottom: 5px;
  }

  .menu-btn {
    &.dropdown-toggle::after {
      border: none;
    }
  }

  .option-btn {
    background-color: $light-color !important;
    color: $font-color;
    border: none;
    width: 100%;
    text-align: left;
    font-size: 0.95em;

    &::after {
      position: absolute;
      right: 10px;
      top: 45%;
    }
  }

  &.filter-dropdown {
    .dropdown-menu {
      &.show {
        width: 50vw;
        border: 1px solid #dddddd3d;
      }
      span.dropdown-item-text {
        display: inline-block;

        small {
          font-size: 0.8em;
          margin-bottom: 5px;
          display: block;
        }
      }

      .filter-btn {
        button {
          background: #efefef !important;
          box-shadow: none;
          border: 2px;
          color: #333;
          font-size: 0.8em;
          padding: 7px 15px;
        }
        .dropdown-menu {
          &.show {
            width: auto;
            max-height: 40vh;
            overflow: auto;
            .dropdown-item {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
}

// Pagination styles
.pagination {
  float: right;

  .page-link {
    padding: 4px 12px;
    border-color: #fff;
    font-size: 0.8rem;
    font-weight: 600;
    color: #009bdf;
  }

  .active .page-link {
    background: linear-gradient(
      90deg,
      rgb(126, 196, 76) 0%,
      rgb(4, 151, 220) 100%
    );
    border-color: #fff;
    color: #fff;
  }
}

// Text overflow styles
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Tooltip styles
.tooltip-inner {
  max-width: none; /* Allow tooltip content to expand */
}

// Custom modal styles
.custom-modal {
  .modal-dialog {
    width: 90%;
    max-width: 90%;

    .modal-body {
      height: 80vh;
      overflow: auto;
      .tab-content {
        height: 82%;
        overflow: auto;
      }
    }
  }
}

.modal-title {
  font-size: 1.2em;
  font-weight: 600;
}

// Form control styles
.form-control,
input {
  border: none;
  background-color: $light-color;
  font-size: 0.85rem;
  font-weight: 600;

  &.form-check-input {
    width: 15px;
    height: 15px;
    cursor: pointer;

    &:hover {
      border-color: #199bd2;
    }
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #ddd;
  }

  &.search-input {
    background-color: $background-color;
    font-size: 1.1em;
    color: $font-color;

    &:focus {
      background-color: $background-color;
    }

    &::placeholder {
      opacity: 0.5;
    }
  }

  &:disabled {
    background-color: #fafafa;
    opacity: 0.8;
  }
}

.form-check-label {
  margin-left: 5px;
}

// Select box styles
.select-box {
  text-transform: capitalize;
}

// Capitalize text
.capitalize {
  text-transform: capitalize;
}

// Card and accordion styles
.card,
.accordion {
  box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  background-color: $background-color;
  border: none;
  border-radius: 0.6em;

  .card-header {
    background-color: #fff;

    label {
      line-height: 2rem;
      font-weight: 600;
      font-size: larger;
    }
  }
}

.card {
  .card-body {
    @include respond-to(sm) {
      padding: 0.7rem;
    }
  }
}

// Accordion button styles
.accordion {
  .accordion-button {
    font-size: 0.9rem;
    font-weight: 600;

    &:not(.collapsed) {
      background: rgb(126 196 76 / 26%);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

// Nav tab styles
.nav-tabs {
  &.hosts-tabs {
    .nav-item {
      button {
        text-transform: uppercase;
      }
    }
  }
  .nav-item {
    button {
      border: none;

      &.nav-link {
        color: $font-color;
        font-size: 0.8rem;
        font-weight: 600;
      }

      &.active {
        border: none;
        color: $primary-color;
        border-bottom: 2px solid $primary-color;
        font-weight: 600;
        background: none;
      }

      &.active:hover {
        border-color: $primary-color;
      }

      &:hover {
        border-color: transparent;
      }
    }
  }
}

.nav-pills {
  .nav-link {
    color: #333;
    &.active {
      background: linear-gradient(
        90deg,
        rgb(126, 196, 76) 0%,
        rgb(4, 151, 220) 100%
      );
    }
  }
}

// List styles
.nav-item {
  ul,
  li {
    list-style: none;
  }
}

// Tree card styles
.tree-card {
  margin-bottom: 10px;
  margin-top: 10px;
  border-left: 1px dashed #ddd;
  padding-left: 10px;

  .tree-body {
    padding: 10px;

    .form-group {
      margin-bottom: 10px;
    }

    .tree-node {
      margin-left: 20px;
    }

    .add-tenant-button {
      margin-top: 10px;
    }
  }
}

// Button styles
// Button styles
.add-btn {
  color: #fff;
  background-color: transparent;
  padding: 5px 15px;
  font-size: 0.85em;
  border: 2px solid #fff;
  width: 135px;

  &.edit {
    width: 100%;
    border-radius: 5px;
    color: #009bdf;
  }
}

.edit-btn {
  color: #fff;
  background-color: orange;
  padding: 7px 10px !important;
  font-size: 0.85em;
  border: 2px solid #fff;
  width: 135px;

  &.edit {
    width: 100%;
    border-radius: 5px;
    color: #009bdf;
  }
  &:hover {
    background-color: #ff9900; /* Slightly darker orange for hover */
    color: #fff; /* Adjust text color if needed */
    border-color: #ff9900; /* Optional: matching border color */
  }
}

.view-btn {
  border: none;
  background-color: transparent;
  color: $primary-color;
  padding: 0 5px;
  margin: 0;
  line-height: 0.8em;
  font-size: 0.85em;
}

.back-btn {
  margin-top: -15px;
  width: 100px;
}

// Search box styles
.search-box {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

// Link styles
.open-link {
  text-decoration: none;
  font-size: 0.85em;
  background-color: $primary-color;
  color: $background-color;
  padding: 3px 10px;
  border-radius: 10px;
}

// Submenu styles
.submenu {
  list-style: none;
  padding-left: 20px;

  li {
    margin-bottom: 4px;
  }
}

// Submenu toggle button styles
.submenu-toggle::before {
  content: "\25B6"; /* Unicode for right-pointing triangle (▶) */
  display: inline-block;
  margin-right: 5px;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
  position: absolute;
  right: 15px;
}

// Rotate the triangle when submenu is expanded
.submenu-toggle.open::before {
  transform: rotate(90deg);
}

// Close button styles
.close-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: #ff6060;
  border: none;
  width: 20px;
  height: 20px;
  color: $background-color;
  text-align: center;
  font-size: 0.75em;
  line-height: 1.2em;
  cursor: pointer;
}

// Copy button styles
.copy-btn {
  color: #aaa;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 5px;
}

td {
  .copy-btn {
    position: absolute;
    left: 0;
    top: 40%;
  }
}

// Top section styles
.top-section {
  position: relative;
  margin: 15px;
  border-radius: 10px;
  padding: 15px;

  &::before {
    border-radius: 15px;
  }

  &.no-min {
    min-height: fit-content;
  }

  h5 {
    color: $background-color;
    text-transform: uppercase;
  }
}

// Middle section styles
.middle-section {
  padding: 1em;
}

// Category tabs styles
.category-tabs {
  padding: 15px;

  .nav-tabs {
    width: auto;
    display: block;
    height: fit-content;
    width: 25%;
    margin-right: 2%;

    .nav-link {
      width: 100%;
      text-align: left;
      padding: 15px 20px;
    }
  }

  .tab-content {
    width: 73%;
  }
}

// Manage tabs styles
.manage-tabs {
  .tab-pane {
    .container {
      margin: 0;
      padding: 0;
      padding-top: 1rem;
    }
  }
}

// Box styles
.box {
  border-radius: 0.6em;
  cursor: pointer;

  .h5 {
    @include respond-to(xl) {
      font-size: 0.9em;
    }
    @include respond-to(lg) {
      font-size: 1em;
    }
    @include respond-to(md) {
      font-size: 0.9em;
    }

    font-weight: 800;
  }

  .col {
    text-align: center;

    h2 {
      font-weight: 800;
    }

    .card-title {
      text-transform: uppercase;
      font-size: 0.9em;
      color: #666;
      margin-bottom: 15px;
      &.h5 {
        // font-size: 1em;
      }
    }

    .card-text {
      font-size: 0.8em;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .box-icon {
    width: 1.1rem;
    height: 1.1rem;
    padding: 1.1rem;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 50% 0 0 50%;
    background: #fff;
    color: #fff;
    position: absolute;
    // top: 0;
    right: -2px;
    cursor: pointer;
  }
}

// Dashboard styles
.dashboard {
  .top-section {
  }

  .middle-section {
    .sub-section {
      min-height: 20em;
    }
  }
}

// Color classes
.red,
.failed {
  &.bg {
    background: linear-gradient(90deg, rgb(255 63 63) 0%, rgb(255 99 99) 100%);
    color: #fff !important;
  }
  &.cl {
    color: #ff5a5a;
  }
}

.green,
.success {
  &.bg {
    background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
    color: #fff !important;
  }
  &.cl {
    color: #2ecc71;
  }
}

.blue {
  &.bg {
    background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
    color: #fff !important;
  }
  &.cl {
    color: #1e90ff;
  }
}

.orange {
  &.bg {
    background: linear-gradient(60deg, #ff8f5e, #fb8c00);
    color: #fff !important;
  }
  &.cl {
    color: #ff9800;
  }
}

.violet {
  &.bg {
    background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
    color: #fff !important;
  }
  &.cl {
    color: #d33ab0;
  }
}
// linear-gradient(60deg,#26c6da,#00acc1)
.yellow,
.missing {
  &.bg {
    background: linear-gradient(
      to bottom,
      #ffd634 0%,
      rgb(255 121 33 / 70%) 100%
    );
    color: #fff !important;
  }
  &.cl {
    color: rgb(255 192 17);
  }
}

.violet1 {
  &.bg {
    background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
    color: #fff !important;
  }
  &.cl {
    color: #825ee4;
  }
}

.btn {
  border: none;
  font-size: 0.85em;
  padding: 5px 10px;
  font-weight: 800;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

// Button primary styles
.btn-primary {
  background: linear-gradient(
    90deg,
    rgb(126, 196, 76) 0%,
    rgb(4, 151, 220) 100%
  );
  padding: 7px 10px;
}
/* Pressed Effect */
.btn-primary:active {
  box-shadow: 0 5px rgba(126, 196, 76, 0.5); /* Pressed shadow */
  // transform: translateY(4px); /* Move down */
}
// Button secondary styles
.btn-secondary {
  background: linear-gradient(90deg, rgb(4, 151, 220), rgb(126, 196, 76));
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 5px;
  border: 2px solid rgba(126, 196, 76, 1);
}

.btn-secondary:hover {
  border-image: linear-gradient(
      90deg,
      darken(rgb(4, 151, 220), 10%),
      darken(rgb(126, 196, 76), 10%)
    )
    1;
}

// Delete button styles
.delete-btn {
  width: auto;
  padding: 5px 10px;
  background: linear-gradient(90deg, rgb(255 73 73) 0%, #d92032 100%);
  position: absolute;
  right: 0;
}

// Step form styles
.step-form {
  .nav-tabs {
    display: block;
    width: 20%;
    float: left;
    border: none;

    .nav-item {
      button {
        padding: 15px;
        width: 100%;
        text-align: left;
        font-weight: 600;
        font-size: 0.85em;
      }
    }
  }

  .tab-content {
    width: 80%;
    float: left;
    padding: 20px;
    min-height: 50vh;
    border-left: 1px dashed #b3b3b3;
  }
}


.tab-content {
  padding: 0 10px;
}

.profile {
  .hover-item{
    border: 2px solid rgba(126, 196, 76, 1);
    text-align: center;
  }
}
// Controller organization styles
.controller-org {
  .form-label {
    font-size: 0.8em;
  }
}

// Form label styles
.form-label {
  font-size: 1rem;
  color: #343a40;
}

// Timeline styles
.timeline {
  border-left: 1px dotted rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    width: 12px;
    height: 12px;
    border: 2px solid #c0bebe;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: -6px;
    top: 30px;
  }

  &.red {
    &::before,
    .details {
      border-color: #ff5a5a;
    }
  }

  &.green {
    &::before,
    .details {
      border-color: #2ecc71;
    }
  }

  &.blue {
    &::before,
    .details {
      border-color: #1e90ff;
    }
  }

  &.orange {
    &::before,
    .details {
      border-color: #ff9800;
    }
  }

  &.violet {
    &::before,
    .details {
      border-color: #d33ab0;
    }
  }

  &.yellow {
    &::before,
    .details {
      border-color: #ffd634;
    }
  }

  .details {
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 10px;
    padding: 7px 0;
    border-radius: 5px;
    box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;

    .time {
      font-size: 0.7rem;
      color: #333;
    }

    .badge {
      text-transform: uppercase;
    }

    border-left: 3px solid;
  }
}

// Log styles
.log {
  text-align: end;

  b {
    font-size: 0.75em;
    color: #666;
  }

  small {
    text-transform: uppercase;
    font-size: 0.75em;
    font-weight: 800;
  }
}

// Sub-info styles
.sub-info {
  display: block;
  margin-top: -5px;
  color: #666;
}

// Details styles
.details {
  span {
    font-size: 0.75rem;
    color: #777777;
  }

  b {
    font-size: 0.95em;
  }
}

// Bottom-right alert styles
.bottom-right-alert {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1050;
  opacity: 1;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.alert-hidden {
  opacity: 0;
  transform: translateX(100%);
}

.alert-visible {
  opacity: 1;
  transform: translateX(0);
  z-index:99999
}

// .alert {
//   background: #fff;
//   box-shadow: -1px 1px 4px 0px hsl(0deg 0% 0% / 24%),
//     0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
//   font-size: 0.8rem;
//   border: none;

//   &.alert-success.alert-dismissible.show {
//     border-left: 5px solid green;
//     color: green;
//   }
// }

// Activities styles
.activities-sm {
  max-height: 80vh;
  overflow: auto;
}

// Gradient text styles
.gradient-txt {
  background: linear-gradient(
    90deg,
    rgb(126, 196, 76) 0%,
    rgb(4, 151, 220) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-size: 1.9em;
  font-weight: bold;
  display: inline-block;
}

// Logo background styles
.logo-bg {
  background-image: url("https://versa-networks.com/wordpress/wp-content/themes/bootstrap-theme/assets/images/logos/versa-logo.svg");
  width: 60px;
  height: 60px;
}

// List group item styles
.list-group-item {
  span {
    font-size: 0.9rem;
    float: left;
  }

  b {
    float: right;
  }
}

// Highlight styles
.highlight {
  background-color: yellow;
}

// Popover styles
.popover {
  box-shadow: 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  border: none !important;

  .popover-arrow::before {
    border: none !important;
  }
}

/* Floating feedback */
.floating-button {
  position: fixed;
  top: 40%;
  right: -30px;
  z-index: 1000;
  padding: 0.8rem 1rem;
  transform: rotate(-90deg);
}

.side-navbar {
  transition: width 0.3s;
  .sidebar {
    width: 15%;
    @include respond-to(sm, max) {
      padding: 0px !important;
      width: 10%;
    }
    &.collapsed {
      width: auto;
      transition: width 0.3s;
      .sidebar-sticky {
        width: 50px;
        overflow: hidden;
        @include respond-to(sm, max) {
          width: 40px;
        }
        .navbar-brand {
          margin: 13px;
          margin-bottom: 40px;
        }
        .nav-link {
          padding: 18px;
          &.active {
            margin: 0;
            padding: 17px;
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.toggle-button {
  padding: 5px 10px;
  margin-right: 15px;
  @include respond-to(sm, max) {
    display: none;
  }
}

.nav-icon.icon-collapsed {
  font-size: 0.9rem; /* Adjust icon size */
  transition: font-size 0.3s;
}

.collapsed .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logo-collapsed {
  height: 35px;
  transition: height 0.3s;
}

.content-container {
  width: 86%;
  margin-left: 14%;
  z-index: 999;
  @include respond-to(md, max) {
    width: 90%;
    margin-left: 10%;
  }
  &.main-collapsed {
    width: 97%;
    margin-left: 3%;
  }
}

.loader {
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid rgba(126, 196, 76, 1);
  border-bottom-color: rgba(4, 151, 220, 1);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader-content {
  vertical-align: top;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-marker-cluster {
  background-color: rgba(255, 0, 0, 0.6);
  border-radius: 50%;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  font-size: 16px;
}

button.gm-ui-hover-effect {
  position: absolute !important;
  right: 0;
}

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  background-color: #efefef;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style-mtc button,
button.gm-svpc,
img[alt="Google"],
.gm-style-cc {
  display: none !important;
}

.updated-box {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 0.85rem;
  span {
    display: inline-block;
    margin: 0 5px;
    font-size: 0.8rem;
    color: #777;
  }
  b {
    // color: #009bdf;
  }
}

.download-btn {
  button {
    background: none;
    box-shadow: none;
    color: rgba(4, 151, 220, 1);
    font-size: 1rem;
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background: linear-gradient(
        90deg,
        rgb(126, 196, 76) 0%,
        rgb(4, 151, 220) 100%
      ) !important;
      color: #fff;
      outline: none;
    }
  }
  .dropdown-item {
    font-size: 0.9rem;
    padding: 5px 10px;
  }
}

.hover-item {
  cursor: pointer;
  &:hover {
    color: #fff !important;
    background: linear-gradient(
      90deg,
      rgb(126, 196, 76) 0%,
      rgb(4, 151, 220) 100%
    );
    box-shadow: 0 0 8px 5px hsla(0, 0%, 0%, 0.13),
      0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
    // border-radius: 2px;
    span.link,
    .gradient-txt,
    .link {
      color: #fff !important;
    }
    .gradient-txt {
      -webkit-text-fill-color: #fff;
    }
  }
  &.icon:hover {
    background: transparent;
    box-shadow: none !important;
    color: rgba(4, 151, 220, 1) !important;
  }
}
.dc-marker {
  background-color: #fff;
  border-radius: 50px;
  overflow: hidden;

  div {
    color: #333 !important;
  }
}
.marker-label {
  background-color: #fff;
  columns: #333;
  padding: 10px;
  border-radius: 10px;
  margin-top: 80px;
  font-size: 12px !important;
  font-weight: bold;
}

.cust-info-window {
  position: absolute;
  right: 0;
  height: 100%;
  width: 200px;
}

.xl-modal {
  max-width: 95vw !important;
}
.l-modal {
  max-width: 80vw !important;
}

.react-flow__attribution {
  display: none !important;
}

.download-link {
  background: transparent;
  border: none;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

/* CSS for the table header resizable columns */
.resizable {
  position: relative;
  overflow: hidden;
}

.resizable .resizer {
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  cursor: col-resize; /* This makes it clear that the column is resizable */
  user-select: none;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.resizable:hover .resizer {
  background-color: rgba(0, 0, 0, 0.1); /* Highlight on hover */
}

/* Optional: for added clarity */
.resizable .resizer:hover {
  background-color: rgba(0, 0, 0, 0.3); /* Darker highlight on resizer hover */
}

.color-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px 0 15px;
}

.progress,
.progress-stacked {
  height: 1.7rem;
}

.progress-bar {
  font-weight: 800;
  font-size: 1.2em;
}

.marker-cluster-small,
.custom-marker-cluster {
  background-color: #fff !important;
  box-shadow: 0 0 8px 5px hsl(0deg 0% 0% / 13%),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  div {
    background: linear-gradient(
      90deg,
      rgb(126, 196, 76) 0%,
      rgb(4, 151, 220) 100%
    );
    color: #fff;
    font-weight: 800 !important;
  }
}

.custom-marker-cluster {
  div {
    border-radius: 50px;
    text-align: center;
    border: 4px solid #fff;
    height: 100%;
    line-height: 2rem;
    font-size: 1rem;
  }
}

.leaflet-marker-pane img {
  background-color: #fff !important;
  border: 4px solid #fff;
  border-radius: 50px;
  box-shadow: 0 0 8px 5px hsl(0deg 0% 0% / 13%),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

.region-label {
  font-size: 12px;
  color: #000;
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  text-align: center;
  white-space: nowrap;
  width: auto !important;
}

/* Shimmer effect for background */
.shimmer {
  position: relative;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
}

.shimmer-box {
  background-color: #e0e0e0;
  border-radius: 4px;
}

.shimmer-text {
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 4px;
}

/* Shimmer effect animation */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Customize shimmer effect for different elements */
.shimmer-text {
  background: #e0e0e0;
  height: 1rem;
  margin-bottom: 0.5rem;
}

.shimmer-box {
  height: 150px;
}

.shimmer.shimmer-box {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
}

/* Adjust shimmer for headers and other elements */
h5.shimmer-text,
h6.shimmer-text {
  height: 1.2rem;
  width: 80%;
}

/* Shimmer circle styling */
.shimmer-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

/* Shimmer for specific labels and numbers in the report */
.shimmer-item-label {
  height: 1rem;
  width: 70%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite;
}

.shimmer-item-number {
  height: 1rem;
  width: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  animation: shimmer 1.5s infinite;
}

/* Responsive Shimmer */
@media (max-width: 768px) {
  .shimmer-box {
    height: 120px;
  }
  .shimmer-text {
    width: 70%;
  }
}

/* Shimmer Animation Keyframes */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.shimmer-text-host {
  background: #e0e0e0;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.shimmer-text-dark {
  background: #cecece;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

.shimmer-button {
  background: #e0e0e0;
  animation: shimmer 1.5s infinite linear;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -200%;
  }
  100% {
    background-position: 200%;
  }
}

.headends-category-shimmer {
  margin: 0px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

/* Shimmer Table CSS */

.shimmer-table-container {
  width: 100%;
  padding: 20px;
}

.shimmer-table-header,
.shimmer-table-row {
  display: flex;
  margin-bottom: 10px;
}

.shimmer-text-header,
.shimmer-text {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite linear;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.shimmer-table-header {
  margin-bottom: 10px;
  font-weight: bold;
}

.shimmer-text-header {
  height: 20px;
}

.shimmer-table-row {
  margin-bottom: 5px;
}

.to-email {
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
}

ul.select-list {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;

    input {
      margin-right: 5px;
    }
  }
}

.cron-builder,
.react-js-cron,
.react-js-cron-dropdown {
  z-index: 9999 !important;
  position: relative;
}

.expanded {
  td {
    background-color: #fafafa !important;
  }
  .custom-table {
    th {
      background-color: #eeeeee !important;
    }
  }
}

.bottom-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f8f9fa;
  padding: 15px;
  text-align: center;
}